import classNames from "classnames"
import React from "react"
import classes from "./EditorialRow.module.css"
import { CustomCol, CustomRow } from "../../../../ui/spacings"
import {
  toColResponsiveOptions,
  toResponsiveOptions,
} from "../../converters/responsiveOptions"
import { EditorialItem } from "../../types"
import EditorialContentBox from "../EditorialContentBox"
import EditorialContents from "../EditorialContents"

interface Props {
  content: EditorialItem
  root?: boolean
}

const EditorialRow = ({ content, root }: Props) => {
  const responsiveOptions = toResponsiveOptions(content.responsiveOptions)

  return (
    <EditorialContentBox
      responsiveOptions={responsiveOptions}
      root={root}
      fluid={content.fluid}
    >
      <CustomRow
        className={classNames({
          [classes.noGuttersDesktop]:
            responsiveOptions.default.visibility.fullWidth,
          [classes.noGuttersMobile]:
            responsiveOptions.mobile.visibility.fullWidth,
        })}
        alignItems={content.alignItems}
        justify={content.justifyContent}
        spacing={content.fluid !== true}
      >
        {content.columns?.map((col: any, index: number) => (
          <CustomCol
            key={index}
            sm={col.display?.width}
            xs={col.display.widthMobile ?? col.display?.width}
            responsiveOptions={toColResponsiveOptions(col.responsiveOptions)}
          >
            <EditorialContents contents={col.content} root={false} />
          </CustomCol>
        ))}
      </CustomRow>
    </EditorialContentBox>
  )
}

export default EditorialRow
