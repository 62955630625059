import React from "react"
import { CustomContainer } from "../../../../ui/spacings"
import { ResponsiveOptions } from "../../types"
import { getSpacingPx } from "../../utils/spacing"
import classes from "./EditorialContentBox.module.css"

interface Props {
  fluid?: boolean
  responsiveOptions: ResponsiveOptions
  children: any
  root?: boolean
}

const EditorialContentBox = ({
  children,
  responsiveOptions,
  root,
  fluid,
}: Props) => {
  return (
    <div
      style={{
        ["--sm-mt" as any]: getSpacingPx(
          responsiveOptions.default.margins?.top
        ),
        ["--sm-mb" as any]: getSpacingPx(
          responsiveOptions.default.margins?.bottom
        ),
        ["--sm-ml" as any]: getSpacingPx(
          responsiveOptions.default.margins?.left
        ),
        ["--sm-mr" as any]: getSpacingPx(
          responsiveOptions.default.margins?.right
        ),
        ["--sm-pt" as any]: getSpacingPx(
          responsiveOptions.default.paddings?.top
        ),
        ["--sm-pb" as any]: getSpacingPx(
          responsiveOptions.default.paddings?.bottom
        ),
        ["--sm-pl" as any]: getSpacingPx(
          responsiveOptions.default.paddings?.left
        ),
        ["--sm-pr" as any]: getSpacingPx(
          responsiveOptions.default.paddings?.right
        ),
        ["--sm-display" as any]: responsiveOptions.default.visibility?.hidden
          ? "none"
          : "block",
        ["--xs-mt" as any]: getSpacingPx(
          responsiveOptions.mobile.margins?.top ??
            responsiveOptions.default.margins?.top
        ),
        ["--xs-mb" as any]: getSpacingPx(
          responsiveOptions.mobile.margins?.bottom ??
            responsiveOptions.default.margins?.bottom
        ),
        ["--xs-ml" as any]: getSpacingPx(
          responsiveOptions.mobile.margins?.left ??
            responsiveOptions.default.margins?.left
        ),
        ["--xs-mr" as any]: getSpacingPx(
          responsiveOptions.mobile.margins?.right ??
            responsiveOptions.default.margins?.right
        ),
        ["--xs-pt" as any]: getSpacingPx(
          responsiveOptions.mobile.paddings?.top ??
            responsiveOptions.default.paddings?.top
        ),
        ["--xs-pb" as any]: getSpacingPx(
          responsiveOptions.mobile.paddings?.bottom ??
            responsiveOptions.default.paddings?.bottom
        ),
        ["--xs-pl" as any]: getSpacingPx(
          responsiveOptions.mobile.paddings?.left ??
            responsiveOptions.default.paddings?.left
        ),
        ["--xs-pr" as any]: getSpacingPx(
          responsiveOptions.mobile.paddings?.right ??
            responsiveOptions.default.paddings?.right
        ),
        ["--xs-display" as any]:
          responsiveOptions.mobile.visibility?.hidden ??
          responsiveOptions.default.visibility?.hidden
            ? "none !important"
            : "block",
      }}
      className={classes.responsiveOptions}
    >
      {root ? (
        <CustomContainer
          fluid={responsiveOptions.default.visibility.fullWidth || fluid}
          desktopNoGutters={
            responsiveOptions.default.visibility.fullWidth || fluid
          }
          mobileNoGutters={
            responsiveOptions.mobile.visibility.fullWidth || fluid
          }
        >
          {children}
        </CustomContainer>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export default EditorialContentBox
