import React from "react"
import DesktopOnly from "../../../root/punks/mui/visibility/DesktopOnly"
import MobileOnly from "../../../root/punks/mui/visibility/MobileOnly"
import { SanityImg, SanityImgProps } from "../SanityImg"

export interface CustomNextImgProps extends Omit<SanityImgProps, "asset"> {
  value: any
}

const CustomImg = ({ value, ...other }: CustomNextImgProps) => {
  if (!value?.image) {
    return <></>
  }

  if (value.sizeMobile) {
    return (
      <>
        <MobileOnly>
          <SanityImg
            asset={value.image}
            imgWidth={value.sizeMobile?.width}
            imgHeight={value.sizeMobile?.height}
            {...other}
          />
        </MobileOnly>
        <DesktopOnly>
          <SanityImg
            asset={value.image}
            imgWidth={value.size?.width}
            imgHeight={value.size?.height}
            {...other}
          />
        </DesktopOnly>
      </>
    )
  }

  return (
    <SanityImg
      asset={value.image}
      imgWidth={value.size?.width}
      imgHeight={value.size?.height}
      {...other}
    />
  )
}

export default CustomImg
