import React from "react"
import ButtonsBar from "../../../../../ui/organisms/ButtonsBar"
import { toButtonsBarProps } from "../../../converters/toButtonsBarProps"

interface Props {
  content: any
}

const ButtonsBarWrapper = ({ content }: Props) => {
  return <ButtonsBar {...toButtonsBarProps(content.item.buttonsBar)} />
}

export default ButtonsBarWrapper
