import {
  ColResponsiveOption,
  ColResponsiveOptions,
  Margins,
  ResponsiveOption,
  ResponsiveOptions,
  VerticalMargins,
  Visibility,
} from "../types"

const toMargins = (item: any): Margins => ({
  top: item?.top,
  bottom: item?.bottom,
  left: item?.left,
  right: item?.right,
})

const toPaddings = (item: any): Margins => ({
  top: item?.top,
  bottom: item?.bottom,
  left: item?.left,
  right: item?.right,
})

const toVisibility = (item: any): Visibility => ({
  hidden: item?.hidden === true,
  fullWidth: item?.fullWidth === true,
})

const toResponsiveOption = (option: any): ResponsiveOption => ({
  margins: toMargins(option?.margins),
  paddings: toPaddings(option?.paddings),
  visibility: toVisibility(option?.visibility),
})

export const toResponsiveOptions = (item: any): ResponsiveOptions => ({
  default: toResponsiveOption(item?.default),
  mobile: toResponsiveOption(item?.mobile),
})

const toColMargins = (item: any): VerticalMargins => ({
  top: item?.top,
  bottom: item?.bottom,
})

const toColResponsiveOption = (option: any): ColResponsiveOption => ({
  margins: toColMargins(option?.margins),
  paddings: toPaddings(option?.paddings),
})

export const toColResponsiveOptions = (item: any): ColResponsiveOptions => ({
  default: toColResponsiveOption(item?.default),
  mobile: toColResponsiveOption(item?.mobile),
})
