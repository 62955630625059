import React from "react"
import { EditorialItem } from "../../types"
import EditorialContent from "../EditorialContent"
import EditorialContentBlock from "../EditorialContentBlock"
import EditorialRow from "../EditorialRow"

interface Props {
  contents: EditorialItem[]
  query?: any
  root?: boolean
}

const EditorialContents = ({ contents, root, query }: Props) => {
  if (!contents) {
    return <></>
  }

  return (
    <>
      {contents.map((content, index) => {
        switch (content._type) {
          case "row":
            return <EditorialRow content={content} root={root} key={index} />
          case "editorialContentBlock":
            return (
              <EditorialContentBlock
                content={content}
                root={root}
                key={index}
              />
            )
          case "editorialContentContainer":
            return (
              <EditorialContent
                content={content}
                root={root}
                key={index}
                query={query}
                fluid
              />
            )
          default:
            return (
              <EditorialContent
                query={query}
                content={{ item: content }}
                root={root}
                key={index}
              />
            )
        }
      })}
    </>
  )
}

EditorialContents.defaultProps = {
  root: true,
}

export default EditorialContents
