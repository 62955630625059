import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"
import { absoluteUrl } from "../../../root/routing/path"

interface Props {
  title: string
  description: string
  robots: string
  children?: any
}

export const Seo = ({ title, description, robots, children }: Props) => {
  const router = useRouter()

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robots} />
      <link
        rel="canonical"
        href={absoluteUrl(router.asPath?.split("?")?.[0] ?? "")}
      />
      {children}
    </Head>
  )
}
