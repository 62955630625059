import React from "react"
import dynamic from "next/dynamic"
import { toResponsiveOptions } from "../../converters/responsiveOptions"
import { EditorialItem } from "../../types"
import EditorialContentBox from "../EditorialContentBox"
import PageHeaderWrapper from "./wrappers/PageHeaderWrapper"
import { RawHtml } from "../../../../ui/utilities/Html"
import MobileOnly from "../../../../root/punks/mui/visibility/MobileOnly"
import ButtonsBarWrapper from "./wrappers/ButtonsBarWrapper"

interface Props {
  content: EditorialItem
  root?: boolean
  query: any
  fluid?: boolean
}

const TrustpilotBoxWrapper = dynamic(
  () => import("./wrappers/TrustpilotBoxWrapper")
)
const CategoriesHeroWrapper = dynamic(
  () => import("./wrappers/CategoriesHeroWrapper")
)

const TimelineBlockWrapper = dynamic(
  () => import("./wrappers/TimelineBlockWrapper")
)

const ProductCarouselWrapper = dynamic(
  () => import("./wrappers/ProductCarouselWrapper")
)

const DynamicCarouselWrapper = dynamic(
  () => import("./wrappers/DynamicCarouselWrapper")
)

const HorizontalTagsWrapper = dynamic(
  () => import("./wrappers/HorizontalTagsWrapper")
)

const ResponsiveHeroWrapper = dynamic(
  () => import("./wrappers/ResponsiveHeroWrapper")
)

const MarketingFeaturesWrapper = dynamic(
  () => import("./wrappers/MarketingFeaturesWrapper")
)

const MarketingFormWrapper = dynamic(
  () => import("./wrappers/MarketingFormWrapper")
)

const FaqPanelWrapper = dynamic(() => import("./wrappers/FaqPanelWrapper"))
const StoreLocatorWrapper = dynamic(
  () => import("./wrappers/StoreLocatorWrapper")
)
const StoreReservationWrapper = dynamic(
  () => import("./wrappers/StoreReservationWrapper")
)

const StoreVisitCancellationWrapper = dynamic(
  () => import("./wrappers/StoreVisitCancellationWrapper")
)

const CtaBoxWrapper = dynamic(() => import("./wrappers/CtaBoxWrapper"))
const EditorialBoxWrapper = dynamic(
  () => import("./wrappers/EditorialBoxWrapper")
)
const EditorialBarWrapper = dynamic(
  () => import("./wrappers/EditorialBarWrapper")
)

const BannerWrapper = dynamic(() => import("./wrappers/BannerWrapper"))
const BrandsBarWrapper = dynamic(() => import("./wrappers/BrandsBarWrapper"))
const LinksBarWrapper = dynamic(() => import("./wrappers/LinksBarWrapper"))
const PageBarWrapper = dynamic(() => import("./wrappers/PageBarWrapper"))

// const BoxGridWrapper = dynamic(() => import("./wrappers/BoxGridWrapper"))
const TextBlockWrapper = dynamic(() => import("./wrappers/TextBlockWrapper"))
const ImageBlockWrapper = dynamic(() => import("./wrappers/ImageBlockWrapper"))
const FidelityProgramWrapper = dynamic(
  () => import("./wrappers/FidelityProgramWrapper")
)
const AnimatedHeroWrapper = dynamic(
  () => import("./wrappers/AnimatedHeroWrapper")
)

const GiftGuideWrapper = dynamic(() => import("./wrappers/GiftGuideWrapper"))

const ContactFormWrapper = dynamic(
  () => import("./wrappers/ContactFormWrapper")
)

export const renderContent = (content: any, query: any) => {
  if (content?.item?.tagsBar) {
    return <HorizontalTagsWrapper content={content} />
  }
  if (content?.item?.hero) {
    return <ResponsiveHeroWrapper content={content} />
  }
  if (content?.item?.productsCarousel) {
    return <ProductCarouselWrapper content={content} />
  }
  if (content.item?.dynamicCarousel) {
    return <DynamicCarouselWrapper content={content} />
  }
  if (content?.item?.featuresBox) {
    return <MarketingFeaturesWrapper content={content} />
  }
  if (content?.item?.trustpilotBox) {
    return <TrustpilotBoxWrapper content={content} />
  }
  if (content?.item?.ctaBox) {
    return <CtaBoxWrapper content={content} />
  }
  if (content?.item?.editorialBox) {
    return <EditorialBoxWrapper content={content} />
  }
  if (content?.item?.editorialBar) {
    return <EditorialBarWrapper content={content} />
  }
  if (content?.item?.marketingForm) {
    return (
      <MobileOnly>
        <MarketingFormWrapper content={content} />
      </MobileOnly>
    )
  }
  if (content?.item?.banner) {
    return <BannerWrapper content={content} />
  }
  if (content?.item?.brandsBar) {
    return <BrandsBarWrapper content={content} />
  }
  if (content?.item?.linksBar) {
    return <LinksBarWrapper content={content} />
  }
  if (content?.item?.faqBlock) {
    return <FaqPanelWrapper content={content} query={query} />
  }
  if (content?.item?.textBlock) {
    return <TextBlockWrapper content={content} />
  }
  if (content?.item?.imageBlock) {
    return <ImageBlockWrapper content={content} />
  }
  if (content?.item?.pageHead) {
    return <PageHeaderWrapper content={content} />
  }
  if (content?.item?.pageBar) {
    return <PageBarWrapper content={content} />
  }
  if (content?.item?.storeLocator) {
    return <StoreLocatorWrapper content={content} />
  }
  if (content?.item?.storeVisitReservation) {
    return <StoreReservationWrapper content={content} />
  }
  if (content?.item?.storeVisitCancellation) {
    return <StoreVisitCancellationWrapper content={content} />
  }
  if (content?.item?.timeline) {
    return <TimelineBlockWrapper content={content} />
  }
  if (content?.item?.htmlBlock) {
    return <RawHtml value={content.item.htmlBlock.text} />
  }
  if (content?.item?.fidelityProgram) {
    return <FidelityProgramWrapper content={content} />
  }
  if (content?.item?.categoriesHero) {
    return <CategoriesHeroWrapper content={content} />
  }
  if (content?.item?.buttonsBar) {
    return <ButtonsBarWrapper content={content} />
  }
  if (content?.item?.animatedHero) {
    return <AnimatedHeroWrapper content={content} />
  }
  if (content?.item?.giftGuide) {
    return <GiftGuideWrapper />
  }
  if (content?.item?.contactForm) {
    return <ContactFormWrapper content={content} />
  }

  console.error("Cannot render content item", content)
  return <></>
}

const EditorialContent = ({ content, root, query, fluid }: Props) => {
  return (
    <EditorialContentBox
      responsiveOptions={toResponsiveOptions(content.responsiveOptions)}
      root={root}
      fluid
    >
      {renderContent(content, query)}
    </EditorialContentBox>
  )
}

export default EditorialContent
