import {
  ButtonsBarItem,
  ButtonsBarProps,
} from "../../../ui/organisms/ButtonsBar"
import { toLinkField } from "./link"

const toButtonBarItem = (item: any): ButtonsBarItem => ({
  backgroundColor: item.backgroundColor?.hex,
  icon: item.icon,
  label: item.label,
  link: toLinkField(item.link),
  textColor: item.textColor?.hex,
})

export const toButtonsBarProps = (item: any): ButtonsBarProps => {
  return {
    styles: {
      itemsPerRow: item.data?.styles?.itemsPerRow,
      itemsPerRowMobile: item.data?.styles?.itemsPerRowMobile,
    },
    items: item.data?.contents?.items?.map(toButtonBarItem),
  }
}
