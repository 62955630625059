import React from "react"
import PageHead from "../../../../../ui/organisms/PageHead"
import { toPageHeadProps } from "../../../converters/pageHead"

interface Props {
  content: any
}

const PageHeaderWrapper = ({ content }: Props) => {
  return <PageHead {...toPageHeadProps(content.item.pageHead)} />
}

export default PageHeaderWrapper
