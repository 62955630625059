import React from "react"
import { toResponsiveOptions } from "../../converters/responsiveOptions"
import { renderContent } from "../EditorialContent"
import EditorialContentBox from "../EditorialContentBox"

interface EditorialContentBlockProps {
  content: any
  root?: boolean
  query?: any
}

const EditorialContentBlock = ({
  content,
  root,
  query,
}: EditorialContentBlockProps) => {
  if (!content?.contents) {
    return <></>
  }
  return (
    <EditorialContentBox
      responsiveOptions={toResponsiveOptions(content.responsiveOptions)}
      root={root}
    >
      {content?.contents.map((x: any, i: number) => (
        <React.Fragment key={i}>
          {renderContent(
            {
              item: x,
            },
            query
          )}
        </React.Fragment>
      ))}
    </EditorialContentBox>
  )
}

export default EditorialContentBlock
