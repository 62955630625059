import React from "react"
import { TwRichTextField } from "../../../root/punks/core/fields/RichTextField"
import { titlesGray } from "../../../themes/variables"

export type Align = "center" | "left" | "right"

export interface PageHeadProps {
  align?: Align
  caption: any
  title: any
  subtitle: any
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     textAlign: ({ align }: any) => align,
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(4),
//   },
// }))

const PageHead = ({ align, caption, title, subtitle }: PageHeadProps) => {
  // const classes = useStyles({
  //   align,
  // })
  return (
    <div className="mt-12 mb-8" style={{ textAlign: align }}>
      <TwRichTextField
        value={caption}
        variant="body1"
        color="primary"
        weight="boldest"
      />
      <TwRichTextField
        value={title}
        variant="h2"
        weight="lightest"
        colorValue={titlesGray}
      />
      <TwRichTextField
        value={subtitle}
        variant="subtitle1"
        colorValue={titlesGray}
        weight="lightest"
      />
    </div>
  )
}

export default PageHead
