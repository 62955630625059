import React from "react"
import CustomImg from "../../../cms/components/CustomImg"
import { LinkField } from "../../../containers/editorialPage/converters/link"
import { TwRichTextField } from "../../../root/punks/core/fields/RichTextField"
import {
  CustomImgField,
  RichText,
} from "../../../root/punks/sanity/richText/types"
import ConditionalLink from "../../utilities/ConditionalLink"
import { getPercentageWidth } from "../../utilities/width"
import classes from "./ButtonsBar.module.css"

export interface ButtonsBarItem {
  backgroundColor?: string
  textColor?: string
  link?: LinkField
  label?: RichText
  icon?: CustomImgField
}

export interface ButtonsBarStyles {
  itemsPerRow?: number
  itemsPerRowMobile?: number
}

export interface ButtonsBarProps {
  items: ButtonsBarItem[]
  styles: ButtonsBarStyles
}

const DEFAULT_ITEMS_PER_ROW = 3
const DEFAULT_ITEMS_PER_ROW_MOBILE = 1

const ButtonsBar = ({ items, styles }: ButtonsBarProps) => {
  return (
    <div className="flex flex-wrap justify-center">
      {items.map((x, i) => (
        <div
          key={i}
          style={{
            ["--item-w" as any]: getPercentageWidth(
              styles.itemsPerRowMobile ?? DEFAULT_ITEMS_PER_ROW_MOBILE
            ),
            ["--item-w-md" as any]: getPercentageWidth(
              styles.itemsPerRow ?? DEFAULT_ITEMS_PER_ROW
            ),
          }}
          className={classes.item}
        >
          <ConditionalLink link={x.link?.url}>
            <div
              style={{
                background: x.backgroundColor,
                color: x.textColor,
              }}
              className="flex justify-center px-2 py-4"
            >
              <div className="flex items-center">
                <CustomImg value={x.icon} className="mr-6" />
                <TwRichTextField
                  value={x.label}
                  className={classes.label}
                  variant="subtitle2"
                />
              </div>
            </div>
          </ConditionalLink>
        </div>
      ))}
    </div>
  )
}

export default ButtonsBar
